import __layout from 'nextra-theme-docs'
import __themeConfig from '/vercel/path0/website/theme.config.jsx'


import 'nextra-theme-docs/style.css'
export default function App({Component, pageProps}) {
  return <Component {...pageProps} />
}


const __nextra_internal__ = globalThis[Symbol.for('__nextra_internal__')] ||= Object.create(null)
__nextra_internal__.context ||= Object.create(null)
__nextra_internal__.Layout = __layout
__nextra_internal__.themeConfig = __themeConfig